
import Box from '@mui/material/Box';
import dynamic from 'next/dynamic';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Image from 'next/image';
import Grid from '@mui/material/Grid';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ClearIcon from '@mui/icons-material/Clear';
import Link from 'next/link';
import Badge from '@mui/material/Badge';
import { useSelector } from 'react-redux';
import useGlobalSettings from '@components/_hooks/useGlobalSettings';


const SideBarModalRight = dynamic(() => import("@components/Components/Modals/SideBar/SideBarModal"), {
    ssr: false,
});
const Cart = dynamic(() => import("@components/Components/Nav/Cart"), {
    ssr: false,
});

const HeaderMobileBar1 = ({max_nav_cat, dialog, handleDialog}) => {
  const {cart} = useSelector((state) => state.cartManagementReducer);
  const {getSetting} = useGlobalSettings();
  return (
    <Box sx={{ flexGrow: 1,marginY:2, marginX:2 }}>
      <Grid container columnGap={'2%'} position={'relative'} className='justify-space-between align-items-center position-relative'>
        <Grid item className='text-center' >
          <Box className='f-small' onClick={() => handleDialog('top')} >
            {
              !dialog['top']?.open ? <MenuIcon sx={{fontSize: '30px'}} className='color-grey'/> : <ClearIcon sx={{fontSize: '30px'}} className='color-grey'/>
            }
            <Typography>
              Menu
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Link href="/">
            <Box className='text-center w-100 f-small' >
              <Image style={{objectFit: 'contain'}} src={ `/images/${process.env.brand.acronym}/logo-small.png` } alt={'logo-mobile'} width={getSetting('header_mobile_logo_width', '50')}  height={getSetting('header_mobile_logo_height', '50')}/>
            </Box>
          </Link>
        </Grid>
        <Grid item   className='text-center' >
          <Badge badgeContent={cart.counter} color="primary">
            <Box className='f-small'  onClick={() => handleDialog('right')} >
              <ShoppingCartIcon sx={{fontSize: '30px'}} className='color-grey'/>
              <Typography>
                Cart
              </Typography>
            </Box>
          </Badge>
        </Grid> 
      </Grid>
      <Box>
          {/* {dialog['top'].open && <SideBarModalTop anchor={'top'} title={'Categories'} children={<HeaderCategories handleDialog={() => handleDialog('top')} max_nav_cat={max_nav_cat} type="mobile" />} handleDialog={() => handleDialog('top')} open={dialog['top'].open}/>} */}
      </Box>
      <Box>
          {dialog['right'].open && <SideBarModalRight anchor={'right'} title={'Cart'} children={<Cart handleDialog={() => handleDialog('right')}/>} handleDialog={() => handleDialog('right')} open={dialog['right'].open}/>}
      </Box>
    </Box>
  )
}

export default HeaderMobileBar1